const firebaseConfig = {
  apiKey: "AIzaSyACiqs3syKViNZSWjv0uS7ooe3O5moqGuM",
  authDomain: "newsy-5d3ff.firebaseapp.com",
  databaseURL: "https://newsy-5d3ff.firebaseio.com",
  projectId: "newsy-5d3ff",
  storageBucket: "newsy-5d3ff.appspot.com",
  messagingSenderId: "691575037858",
  appId: "1:691575037858:web:1fd616882ae31eb545dcfb"
};

export default firebaseConfig;